import Axios, { AxiosResponse } from 'axios';
import { BASE_PATH } from '../constants';
import { store } from '../store';
import { HideProgressBar, ShowErrorAlert } from '../store/alerts';
import { SaveUserInfo } from '../store/user';


const getApiPath = (endpoint: string) => BASE_PATH + '/admin/' + endpoint;

const getConfigSetting = (token: string) => {
  let headers: any = {
    'content-type': 'application/json',
  };
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  return {
    headers: headers,
  };
};

const updatePanelVersion = (response: any) => {
  if (response?.data?.data?.panelVersion) {
    let currentPanelVersion = localStorage.getItem('panelVersion');
    if (currentPanelVersion && (currentPanelVersion !== response?.data?.data?.panelVersion)) {
      window.location.reload();
      localStorage.setItem('panelVersion', response?.data?.data?.panelVersion)
    }
    else if (!currentPanelVersion) {
      localStorage.setItem('panelVersion', response?.data?.data?.panelVersion)
    }
  }
}

const handleErrorResponse = (error: any) => {
  const { dispatch } = store;
  dispatch(HideProgressBar());
  if (error.code === "ERR_NETWORK") {
    dispatch(ShowErrorAlert("Internet connection lost"));
    return false
  }
  if (error.response.status == 401) {
    dispatch(ShowErrorAlert(error.response.data.message));
    dispatch(SaveUserInfo({
      userEmail: "",
      username: "",
      accessToken: "",
      refreshToken: "",
      isAdmin: "",
      // isAdmin: true
    }));
  }
  if (error.response.data.code === 400 && error.response.data.message !== '') {
    dispatch(ShowErrorAlert(error.response.data.message));
  } else {
    dispatch(ShowErrorAlert(error.message));
  }
};

export const postRequest = async (endpoint: string, body: any, token?: string) => {
  try {
    const response = await Axios.post(getApiPath(endpoint), JSON.stringify(body), getConfigSetting(token ?? ''));
    updatePanelVersion(response)
    return response;
  } catch (error) {
    handleErrorResponse(error);
    // throw error; // Throw the error to allow the caller to handle it
  }
};


export const putRequest = async (endpoint: string, body: any, token?: string) => {
  try {
    const response = await Axios.put(getApiPath(endpoint), JSON.stringify(body), getConfigSetting(token ?? ''));
    updatePanelVersion(response)
    return response;
  } catch (error) {
    handleErrorResponse(error);
    // throw error; // Throw the error to allow the caller to handle it
  }
};

export const getRequest = async (endpoint: string, token?: string) => {
  try {
    const response = await Axios.get(getApiPath(endpoint), getConfigSetting(token ?? ''));
    updatePanelVersion(response)
    return response;
  } catch (error) {
    handleErrorResponse(error);
    // throw error;
  }
};

export const isApiCodeSucess = (resp: AxiosResponse) => {
  const { dispatch } = store;

  if (resp?.data?.code == 200) {
    return resp?.data?.code == 200;
  } else if (resp?.data?.code == 400) {
    dispatch(ShowErrorAlert(resp.data.message));
    return false;
  } else if (resp?.data?.code == 401) {
    dispatch(ShowErrorAlert("Login Session Expired"));
    dispatch(SaveUserInfo({
      userEmail: "",
      username: "",
      accessToken: "",
      refreshToken: "",
      isAdmin: "",
      // isAdmin: true
    }))

    window.location.reload();
    return false;
  }
  else if (resp?.data?.code == 500) {
    dispatch(ShowErrorAlert("Backend : " + resp.data.message));
    return false;
  }
};
