import { combineReducers } from 'redux';
import { notifierReducer } from "./notifier";
import {alertsReducer} from "./alerts";
import { userInfoReducer } from './user';
import { projectsInfoReducer } from './projects';
import { projectsTokenReducer } from './projectToken';

const rootReducer = combineReducers({
    notifier: notifierReducer,
    alerts: alertsReducer,
    userInfo : userInfoReducer,
    projects : projectsInfoReducer,
    projectsToken:projectsTokenReducer
});

export default rootReducer;
