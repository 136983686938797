export const ADMIN_LOGIN = "signin/";
export const ADD_PROJECT = "register_project/";
export const GET_PROJECT_LIST = "get_projects_list/";
export const UPDATE_SERVICE_TOKEN = "twilio/update_token/";
export const NOTIFIER_ACTION_LIST = "notifier/action/list/";
export const NOTIFIER_LOGS_LIST = "notifier/logs/list/";
export const NOTIFIER_ACTION_STATUS = "notifier/action/status/";
export const NOTIFIER_ACTION_ADD = "notifier/action/add/";
export const NOTIFIER_ACTION_EDIT = "notifier/action/edit/";
export const NOTIFIER_ACTION_DELETE = "notifier/action/delete/";
export const ADMIN_PUSH_CONTENT_ADD = "push_content/add/";
export const ADMIN_PUSH_CONTENT_EDIT = "push_content/edit/"
export const ADMIN_SMS_CONTENT_ADD = "sms_content/add/";
export const ADMIN_EMAIL_CONTENT_ADD = "email_content/add/";
export const ADMIN_EMAIL_CONTENT_EDIT = "email_content/edit/";
export const ADMIN_SMS_CONTENT_EDIT = "sms_content/edit/"
export const NOTIFIER_SPECIFIC_CONTENT_LIST = "notifier/specific_content/list/"
export const UPDATE_SNS_TOKEN = "aws_sns/update_token/";
export const CHANGE_PASSWORD = "change_password/";
export const REGENERATE_UUID = "regenerate_uuid/";
export const GOOGLE_PUSH = "google_push/";
export const AWS_TOKEN = "aws_token/";
export const SMTP_TOKEN = "smtp_token/";
export const SOCIAL_LOGIN_TOKEN = "social_login_token/";
export const GET_SERVICES_TOKEN = "get_services_token/";
export const PROJECTS_ACTIVE_INACTIVE = "projects/activate_deactivate/";
export const ACTIVE_INACTIVE = "service/activate_deactivate/";
export const LOGOUT = "logout/";


export const DELETE_PROJECT = "delete_project/";